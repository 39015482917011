<template>
    <div>
        <div class="modal fade" id="itemModal" tabindex="-1" aria-labelledby="itemModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="itemModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProductCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CTabs>
                            <CTab title="Item" id="tab-1" active>
                                <div class="p-3">
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Kode</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="ProductCode" v-model="ProductCode" class="font-weight-bold" />
                                            <label id="errorProductCode" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold required">Nama</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="ItemName" v-model="ItemName" class="font-weight-bold" />
                                            <label id="errorItemName" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold required">Bar Code</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="BarCode" v-model="BarCode" class="font-weight-bold" />
                                            <label id="errorBarCode" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Status</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="ActiveFlag" class="font-weight-bold" :options="ActiveFlagData" :value.sync="ActiveFlag" />
                                            <label id="errorActiveFlag" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Item Type</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="ItemType" class="font-weight-bold" :options="ItemTypeData" :value.sync="ItemType" />
                                            <label id="errorItemType" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold required">Satuan</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput id="BaseUOM" v-model="BaseUOM" class="font-weight-bold" />
                                            <label id="errorBaseUOM" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold required">Berat</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CInput type="number" id="BaseWeight" v-model="BaseWeight" class="font-weight-bold" />
                                            <label id="errorBaseWeight" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="3">
                                            <label class="form-label font-weight-bold">Type Detail</label>
                                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol sm="4">
                                            <CSelect id="TypeDetailID" class="font-weight-bold" :options="TypeDetailIDData" :value.sync="TypeDetailID" />
                                            <label id="errorTypeDetailID" class="form-error" style="display: none; color: red;"></label>
                                        </CCol>                                    
                                    </CRow>
                                </div>
                            </CTab>
                            <CTab title="Item Detail" id="tab-2">
                                <div class="px-3">
                                    <div v-if="this.ItemId !== ''">
                                        <br>
                                        <datasource ref="dataSource" :data="this.GridData" :schema-model-fields="this.SchemaModel"/>

                                        <kendo-grid ref="itemDetailGrid"
                                                    :data-source-ref="'dataSource'"
                                                    :pageable="true"
                                                    :editable="true"
                                                    :filterable="true"
                                                    :sortable="true"
                                                    :scrollable="true"
                                                    :toolbar="['create']"
                                                    :cellClose="cellClose"
                                                    :resizable="true"
                                                    >

                                            <kendo-grid-column  :field="'uom_conv'"
                                                                :title="'Satuan Konversi'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                            <kendo-grid-column  :field="'conv_amount'"
                                                                :title="'Nilai Konversi'"
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style:'text-align:right;'}"></kendo-grid-column>
                                                                
                                            <kendo-grid-column  :field="'is_secondary'"
                                                                :title="'Konversi Pertama'" 
                                                                :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                                :attributes="{ style:'text-align:center;'}"></kendo-grid-column>

                                            <kendo-grid-column  :title="'&nbsp;'"
                                                                :attributes="{ class: 'k-text-center' }"
                                                                :command="['destroy']"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>
                                </div>
                            </CTab>
                        </CTabs>
                        <br>
                        <label id="errorItemGeneral" class="form-error" style="display: none; color: red;"></label>
                        <br>
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import itemServices from '../Script/ItemServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'ItemForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle: '',
            
            ItemId: null,
            ProductCode: null,
            ItemName: '',
            BarCode: '',
            ActiveFlag: '',
            ActiveFlagData: [],
            ItemType: '',
            ItemTypeData: [],
            BaseUOM: '',
            BaseWeight: 0,
            TypeDetailID: '',
            TypeDetailIDData: [],
                        
            GridData: [],
            SchemaModel: {
                uom_conv: {type: "string", editable: true, validation: {required: true}},
                conv_amount: { type: "number", editable: true, validation: { min: 0.01, required: true}},
                is_secondary: { type: "boolean", editable: true },
            },

            SaveType: '',
            Error: 0,
            gridDetailReload: 0,
        }
    },
    methods: {
        async addClick(){    
            $(".form-error").css("display", "none");        
            this.ModalTitle = 'Add Item: ';
            
            this.ItemId = null;
            this.ProductCode = 'Auto';
            this.ItemName = '';
            this.BarCode = '';
            this.ActiveFlagData = [ {value: 'N', label: 'New'}];
            this.ActiveFlag = this.ActiveFlagData[0].value;

            this.ItemTypeData = [
                {value:'Material', label:'Material'},
                {value:'Premix', label:'Premix'},
                {value:'Formula', label:'Formula'},
                {value:'Non-Stock', label:'Non-Stock'},
                {value:'Stock', label:'Stock'},
                {value:'Lain-lain', label:'Lain-lain'},
            ];
            this.ItemType = this.ItemTypeData[0].value;

            this.BaseUOM = '';
            this.BaseWeight = 0;
            
            this.TypeDetailIDData = await itemServices.getTypeDetailQuery();
            this.TypeDetailID = this.TypeDetailIDData[0].value;
            this.SaveType = 'Add';
            this.GridData = [];
            $('#tab-1').trigger('click');
            $('#tab-2').hide();

            window.$('#itemModal').modal('show');
        },
        async editClick(itemData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Item: ';

            this.ItemId = itemData.item_id;
            this.ProductCode = itemData.product_code;
            this.ItemName = itemData.item_name;
            this.BarCode = itemData.barcode;

            if(itemData.active_flag == "N"){
                itemData.active_flag = "A";
            }
            this.ActiveFlag = itemData.active_flag;
            this.ActiveFlagData = [ {value: 'A', label: 'Active'},
                                    {value: 'I', label: 'In Active'}];

            this.ItemType = itemData.item_type;
            this.ItemTypeData = [
                {value:'Material', label:'Material'},
                {value:'Premix', label:'Premix'},
                {value:'Formula', label:'Formula'},
                {value:'Non-Stock', label:'Non-Stock'},
                {value:'Stock', label:'Stock'},
                {value:'Lain-lain', label:'Lain-lain'},
            ];
            
            this.BaseUOM = itemData.base_uom;
            this.BaseWeight = itemData.base_weight;
            this.TypeDetailID = itemData.type_detail_id;
            this.TypeDetailIDData = await itemServices.getTypeDetailQuery();
            this.SaveType = 'Edit';

            this.GridData = await itemServices.getItemDetail(this.ItemId);

            $('#tab-1').trigger('click');
            $('#tab-2').show();

            this.reloadDetail();
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Item: ';
            }

            window.$('#itemModal').modal('show');
        },
        cellClose(e){
            var grid = this.$refs.itemDetailGrid.kendoWidget();
            var dataItem = e.sender.dataItem($(e.container).parent());

            var dataGridLength = grid.dataSource._data.length;

            if (dataGridLength <= 1) {
                dataItem.is_secondary = true;
            }

            this.GridData = globalfunc.objectToArrayConverter(grid.dataSource._data, 'Item-DetailData');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.ProductCode == ''){
                this.errorShow('errorProductCode');
            }
            if(this.ItemName == ''){
                this.errorShow('errorItemName');
            }
            if(this.BarCode == ''){
                this.errorShow('errorBarCode');
            }
            if(this.ActiveFlag == ''){
                this.errorShow('errorActiveFlag');
            }
            if(this.ItemType == ''){
                this.errorShow('errorItemType');
            }
            if(this.BaseUOM == ''){
                this.errorShow('errorBaseUOM');
            }
            if(this.BaseWeight == '' || this.BaseWeight == null) { 
                this.errorShow('errorBaseWeight');
            }

            var detailData = this.$refs.itemDetailGrid.kendoWidget().dataSource._data;
            var errorUOM = 0;
            var errorAmount = 0;
            var errorisSecondary = 0;
            
            for (let i = 0; i < detailData.length; i++) {
                if (detailData[i].uom_conv == '') {
                    errorUOM++;
                }
                if (detailData[i].conv_amount == 0) {
                    errorAmount++;
                }

                if (detailData[i].is_secondary == true) {
                    errorisSecondary++;
                }
            }

            if(errorUOM > 0) { 
                this.$swal("Error", "Satuan Konversi pada Item Detail tidak boleh kosong", "error" )
                this.Error++;
            }
            if(errorAmount > 0) { 
                this.$swal("Error", "Nilai Konversi pada Item Detail tidak boleh kosong", "error" )
                this.Error++;
            }
            if(errorisSecondary > 1) { 
                this.$swal("Error", "Konversi Pertama pada Item Detail hanya boleh 1 data", "error" )
                this.Error++;
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const itemData = {
                        product_code : this.ProductCode,
                        item_name : this.ItemName,
                        barcode : this.BarCode,
                        active_flag : this.ActiveFlag,
                        item_type : this.ItemType,
                        base_uom : this.BaseUOM,
                        base_weight : this.BaseWeight,
                        type_detail_id : this.TypeDetailID
                    };

                    const variables = {
                        data : itemData
                    }
                    
                    itemServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#itemModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if (this.SaveType == 'Edit') {
                    var uom_conv = [];
                    var detailData = this.$refs.itemDetailGrid.kendoWidget().dataSource._data;
                    for (let i = 0; i < detailData.length; i++) {
                        var detail = {
                            uom_conv: detailData[i].uom_conv,
                            conv_amount: detailData[i].conv_amount,
                            is_secondary: detailData[i].is_secondary
                        }
                        uom_conv.push(detail)
                    }

                    var valueArr = uom_conv.map(function(item){ return item.uom_conv });
                    var isDuplicate = valueArr.some(function(item, idx){ 
                        return valueArr.indexOf(item) != idx 
                    });
                    var isDuplicateMaster = valueArr.includes(this.BaseUOM)
                    
                    if (isDuplicate == false && isDuplicateMaster == false) {
                        this.$loading(true);

                        const itemData = {
                            product_code : this.ProductCode,
                            item_name : this.ItemName,
                            barcode : this.BarCode,
                            active_flag : this.ActiveFlag,
                            item_type : this.ItemType,
                            base_uom : this.BaseUOM,
                            base_weight : this.BaseWeight,
                            type_detail_id : this.TypeDetailID,
                            uom_conv : uom_conv
                        };

                        const variables = {
                            id : this.ItemId,
                            data : itemData
                        }
                        
                        itemServices.editQuery(variables).then(res => {
                            this.$loading(false);
                            this.$swal("Info", response.successUpdate, "success");
                            window.$('#itemModal').modal('hide');
                            this.$props.reload();
                        }).catch(error => {
                            this.$loading(false);
                            var errorMessage = globalfunc.errorExtractor(error)
                            this.$swal("Error", errorMessage, "error");
                        })
                    }
                    else {
                        this.$swal("Error", 'Item Detail is Duplicate', "error");
                    }
                    
                }
             }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        reloadDetail(){
            this.gridDetailReload++;
        },
    }
}
</script>
